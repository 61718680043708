import { FieldError } from "@/interfaces/ErrorInterface";

export const toFieldError = (errors: FieldError[]): any => {
    let fieldError: any = {};

    errors?.map((error: FieldError) => {
        fieldError[error.field] = error.message;
    });

    return fieldError;
};
