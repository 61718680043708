






































































import { Component, Vue, Watch } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { UsernameAndPassword } from "@/interfaces/UserInterface";
import { toFieldError } from "@/helpers/ErrorHandler";
import { COOKIE_NAME } from "@/constants";
import { useSso } from "@/useSso";
import { BjmSSO } from "@/bjmSso";

@Component({
    components: {
        BaseWrapper
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        if (to.query.redirectFrom) {
            next(async (vm: any) => {
                const pushNotification = {
                    message: `Anda harus login terlebih dahulu`
                };

                await vm.$store.dispatch(
                    "meta/addNotification",
                    pushNotification
                );
                vm.redirectFrom = to.query.redirectFrom;
            });
        } else {
            next();
        }
    }
})
export default class AppLogin extends Vue {
    public redirectFrom: string = "";
    public input: UsernameAndPassword = {
        username: "",
        password: ""
    };
    public fieldError: any = {};
    public formKey: number = 0;
    public showPage: boolean = false;
    public disableButton: boolean = false;

    get me(): any {
        return this.$store.state.users.me;
    }

    async signInWithUsernameAndPassword() {
        this.fieldError = {};
        this.disableButton = true;

        const auth = await this.$store.dispatch(
            "users/signInWithUsernameAndPassword",
            this.input
        );

        setTimeout(() => {
            this.fieldError = toFieldError(auth.errors);
            this.formKey++;
            this.disableButton = false;
        }, 500);
    }

    async beforeLoginToServer(result: any) {
        const { data } = result;

        this.checkIsSsoRegistered(data);
    }

    async checkIsSsoRegistered(data: any) {
        this.disableButton = true;

        const http = require("@/http-common").default;
        const { user } = data;

        const response = await http.get("/sso/" + user.id).catch((err: any) => {
            console.log(err.response);
        });

        const auth = await this.$store.dispatch("users/signInWithSso", user.id);

        this.disableButton = false;

        if (!auth) {
            // const notification: any = {
            //     message: 'SSO tidak terdaftar di eCSR',
            //     color: "error",
            //     timeout: -1,
            // };

            // this.$store.dispatch("meta/addNotification", notification);
            // const registerUser = {
            //     username:
            // }
            const newUser = {
                username: user.email,
                password: user.email,
                role: "591b5167-4bcb-4a23-8328-c13befc60d00" // Role Pengusul / PROPOSER
            };

            const registeredUser = await this.$store.dispatch(
                "users/signUpWithUsernameAndPassword",
                newUser
            );
            const authLogin = await this.$store.dispatch(
                "users/signInWithUsernameAndPassword",
                newUser
            );
            const bindSso = await this.$store.dispatch(
                "users/bindSso",
                user.id
            );
            if (bindSso) {
                const notification: any = {
                    message: `<p>Berhasil daftar menggunakan SSO <p><b>Username</b> dan <b>password</b> anda saat ini adalah <b>email</b> SSO anda <p>Silahkan ganti password anda`,
                    timeout: -1
                };

                this.$store.dispatch("meta/addNotification", notification);
            }
        }
    }

    public sso = new BjmSSO();

    async signInWithSso() {
        const self = this;
        this.sso
            .loginWindow(function(result: any) {
                if (result["status"]) {
                    self.beforeLoginToServer(result);
                }
            })
            .catch((err: any) => console.error(err.message));
    }

    errorHandler(error: any) {
        const notification: any = {
            message: "Terjadi kesalahan: " + error.message,
            color: "error",
            timeout: -1
        };

        this.$store.dispatch("meta/addNotification", notification);
        this.disableButton = false;
    }

    async mounted() {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let is_sso = url.searchParams.get("is_sso");
        let token = url.searchParams.get("token");
        const useSso = is_sso == "true";
        const hasToken = !!token;

        if (useSso) {
            this.disableButton = true;
            const self = this;
            this.sso
                .login(async function(result: any) {
                    console.log(result);
                    if (result["status"]) {
                        await self
                            .beforeLoginToServer(result)
                            .catch(err => self.errorHandler(err));
                    }
                })
                .catch(err => self.errorHandler(err));
        }
    }

    @Watch("me", { immediate: true })
    redirectToApp(me: any) {
        const isLoggedIn = !!me;
        const hasProfile = me && me.profile;
        // const accessToken: string = Vue.$cookies.get(COOKIE_NAME);

        // if (!!accessToken && hasProfile) this.$router.push("/app");

        if (isLoggedIn && hasProfile && !!this.redirectFrom)
            this.$router.push("/app/", +this.redirectFrom as any);
        if (isLoggedIn && hasProfile) this.$router.push("/app");
        if (isLoggedIn && !hasProfile) this.$router.push("/app/pengaturan");

        // else this.showPage = true;
        this.showPage = true;
    }
}
